import React from 'react'
import Button from '../Button'
import PlusIcon from '../Icons/PlusIcon'
import MyPostIcon from '../Icons/MyPostIcon'
import BackButton from '../Button/Back'
import LoginButton from '../Button/Login'
import userAuth from '../../utils/userAuth'
import parse from 'html-react-parser'

function HeaderButtonsGroup({
  showBackButton,
  onClickBackButton,
  t,
  onClickMyPost,
  onClickAddPost,
  onClickLogin,
  isMyPostActive,
  isClosed
}) {
  const { isAuthenticated } = userAuth.getAuth()
  return (
    <div className="flex flex-col pt-8 sm:pt-0 justify-center items-center relative">
      {showBackButton && (
        <BackButton
          className="absolute -top-4 -left-1 sm:left-0"
          onClick={onClickBackButton}
        />
      )}
      <h1 className="sm:text-10 text-2xl font-bold text-primary uppercase">
        {t('main_page.your_voice_your_story')}
      </h1>
      {isClosed && (
        <div className="mt-8">
          <p className="text-center text-base sm:text-2xl text-gray">
            {parse(t('main_page.submission_closed'))}
          </p>
        </div>
      )}
      {!isClosed && (
        <>
          {isAuthenticated ? (
            <div className="flex flex-col sm:flex-row justify-center items-center mt-4">
              <Button
                className="min-w-58 sm:mr-8 h-11 "
                classNameText="font-extrabold text-lg uppercase"
                title={t('after_login.add_a_story')}
                Icon={PlusIcon}
                onClick={onClickAddPost}
              />
              <Button
                className="min-w-58 mt-4 sm:mt-0 h-11 "
                classNameText="font-extrabold text-lg uppercase"
                active={isMyPostActive}
                title={t('after_login.my_posts')}
                Icon={MyPostIcon}
                onClick={onClickMyPost}
              />
            </div>
          ) : (
            <LoginButton
              className="mt-4"
              title={t('main_page.login')}
              note={t('main_page.login_button_note')}
              onClick={onClickLogin}
            />
          )}
        </>
      )}
    </div>
  )
}

export default HeaderButtonsGroup
