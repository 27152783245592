import React from 'react'
import { useTranslation } from 'react-i18next'
import { classNames } from '../../utils/index'

function StatusBadge({ status, onDelete, onEdit, shadow }) {
  const { t } = useTranslation()

  if (status === 'pending') {
    return (
      <div
        className={classNames({
          'h-9 overflow-hidden rounded-3xl flex w-full': true,
          'shadow-xl': shadow
        })}
      >
        <div className="w-1/2 bg-white flex justify-center items-center">
          <p className="text-primary" style={{ fontSize: 13 }}>
            {t('my_post.pending_approval')}
          </p>
        </div>
        <div className="w-1/2 bg-primary flex justify-center items-center">
          <div
            className="text-white cursor-pointer w-8 h-8 flex justify-center items-center mr-2"
            onClick={onDelete}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
          </div>
          <div
            className="text-white cursor-pointer w-8 h-8 flex justify-center items-center"
            onClick={onEdit}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              />
            </svg>
          </div>
        </div>
      </div>
    )
  } else if (status === 'approved') {
    return (
      <div className="w-full flex justify-end">
        <div
          className="w-9 h-9 bg-primary text-white rounded-full cursor-pointer flex justify-center items-center"
          onClick={onDelete}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </div>
      </div>
    )
  } else if (status === 'rejected') {
    return (
      <div
        className={classNames({
          'h-9 overflow-hidden rounded-3xl flex w-full': true,
          'shadow-xl': shadow
        })}
      >
        <div className="w-1/2 bg-white flex justify-center items-center">
          <p className="text-primary" style={{ fontSize: 13 }}>
            {t('my_post.rejected_approval')}
          </p>
        </div>
        <div className="w-1/2 bg-primary flex justify-center items-center">
          <div
            className="text-white cursor-pointer w-8 h-8 flex justify-center items-center mr-2"
            onClick={onDelete}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
          </div>
        </div>
      </div>
    )
  }

  return <div></div>
}

export default StatusBadge
