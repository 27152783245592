import React from 'react'

function SendIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22.16 20.6"
      width="22.16"
      height="20.6"
    >
      <g data-name="Layer 2">
        <path
          d="M.85 20.6a.81.81 0 01-.78-1.14c.21-.59.47-1.16.71-1.73 1-2.3 1.92-4.59 2.85-6.9a1.61 1.61 0 000-1.06A729.56 729.56 0 00.19 1.46C0 1.05-.13.66.19.27S.92 0 1.31.18L21.39 9.4c.39.18.78.35.77.92s-.38.69-.75.86l-20 9.19zM2.18 2.35C3.18 4.76 4.1 7 5.05 9.21c.07.16.39.27.6.27h11.44a4.18 4.18 0 00.48-.08zm15.37 8.84a1.25 1.25 0 00-.27-.07H5.51a.79.79 0 00-.55.45c-.64 1.48-1.24 2.97-1.85 4.43-.29.68-.56 1.37-.91 2.22z"
          fill="#fff"
          data-name="Layer 1"
        />
      </g>
    </svg>
  )
}

export default SendIcon
