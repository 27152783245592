import React from 'react'
import CommentItem from './CommentItem'
import { classNames } from 'utils'
function CommentListing({ comments }) {
  return (
    <div
      className={classNames({
        'mt-4 pt-2 pb-5': true,
        'border-t border-primary': comments && comments.length > 0
      })}
    >
      {(comments || []).map((comment, index) => (
        <CommentItem
          key={index}
          content={comment.content}
          creator={comment.creator}
        />
      ))}
    </div>
  )
}

export default CommentListing
