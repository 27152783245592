import React from 'react'
import LikeCommentBox from './LikeCommentBox'
import { classNames } from '../../utils'
import PlayIcon from '../Icons/PlayIcon'
import ShowMore from 'react-show-more'
import StatusBadge from './StatusBadge'
import userAuth from '../../utils/userAuth'

function Post({ onClick, data, dayjs, onEdit, onDelete }) {
  const {
    uuid,
    like_count,
    comment_count,
    content,
    creator,
    status,
    is_liked,
    is_mine,
    media_type,
    media,
    timestamps
  } = data

  function handleClick() {
    onClick(uuid)
  }

  const url = !!media ? media.preview_url : ''
  const { width, height } = !!media ? media.metadata : {}

  function handleDelete(event) {
    event.preventDefault()
    event.stopPropagation()
    if (typeof onDelete === 'function') {
      onDelete(uuid)
    }
  }

  function handleEdit(event) {
    event.preventDefault()
    event.stopPropagation()
    if (typeof onEdit === 'function') {
      onEdit(uuid)
    }
  }

  return (
    <div
      onClick={handleClick}
      style={{ width: 282 }}
      className={classNames({
        'rounded-xl m-4.25 bg-white overflow-hidden shadow-2xl cursor-pointer': true
      })}
    >
      <div
        className={classNames({
          'relative flex justify-center items-center': true,
          'bg-black': !!media
        })}
      >
        {url && (
          <img
            className={classNames({
              'w-full max-h-72': width >= height,
              'h-72': height > width
            })}
            src={url}
            alt=""
            onContextMenu={(e) => e.preventDefault()}
          />
        )}

        {media_type === 'video' && !!media && (
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
            <PlayIcon />
          </div>
        )}
        {is_mine && (
          <div
            className={classNames({
              'w-full p-2': true,
              'bg-gray-opacity-20':
                !media && ['pending', 'rejected'].includes(status),
              'absolute h-full left-0 top-0 bg-gray-opacity-20': !!media
            })}
          >
            <StatusBadge
              status={status}
              onDelete={handleDelete}
              onEdit={handleEdit}
            />
          </div>
        )}
      </div>
      <div>
        <div className="p-4 pb-2 w-full">
          <div
            className={classNames({
              'text-gray w-full': true,
              'text-xl': !media
            })}
          >
            <ShowMore
              lines={media ? 5 : 12}
              more={''}
              less={''}
              maxWidth={250}
              anchorClass="text-primary"
            >
              {content || ''}
            </ShowMore>
          </div>
        </div>
        <div className="flex flex-col p-4 bg-white">
          <div>
            <p className="font-bold text-gray text-right">{creator}</p>
          </div>
          <div className="flex justify-between items-center">
            <LikeCommentBox
              uuid={uuid}
              likeCount={like_count}
              commentCount={comment_count}
              isLiked={is_liked}
            />
            <p className="text-gray text-xs text-right">
              {dayjs(timestamps).fromNow()}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Post)
