import React, { useState, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import nextIcon from '../../images/next-icon.svg'
import emailIcon from '../../images/email-icon.svg'
import axios from '../../utils/axios'
import userAuth from '../../utils/userAuth'
import ErrorPopup from './ErrorPopup'
import parse from 'html-react-parser'

function LoginLayout() {
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const [currentState, setCurrentState] = useState(0)

  const [openPopup, setOpenPopup] = useState(false)
  function validateEmail(email) {
    const re = /\S+@\S+\.\S+/
    return re.test(String(email).toLowerCase())
  }

  function reducer(state, action) {
    const updatedValues = state
    if ('email' in action) {
      updatedValues.email = action.email.replace(/\s/g, '')
      updatedValues.error = false
    }
    if ('error' in action) {
      updatedValues.error = action.error
    }
    if ('render' in action) {
      setCurrentState(1 - currentState)
    }
    return updatedValues
  }
  const [values, dispatch] = useReducer(reducer, { email: '', error: '' })
  const { email, error } = values

  function onFormSubmit(e) {
    e.preventDefault()
    handleSubmit()
  }

  async function handleSubmit() {
    if (!email || !validateEmail(email)) {
      dispatch({
        error: t('login.email_invalid'),
        render: true
      })
      setOpenPopup(true)
      return
    }
    try {
      const resp = await axios.post('/auth/sign-in', {
        registrant: {
          email_address: email
        }
      })
      const { success, data } = resp
      if (success) {
        userAuth.setAuth({ auth_token: data['token'] })
        window.location.href = `/${language}`
      } else {
        dispatch({
          error: t('login.login_error'),
          render: true
        })
        setOpenPopup(true)
      }
    } catch (error) {
      dispatch({
        error: t('login.login_error'),
        render: true
      })
      setOpenPopup(true)
    }
  }

  function handleChangeInput(value) {
    dispatch({ email: value.target.value, render: true })
  }

  return (
    <div className="md:w-11/12 lg:w-footer-rate mx-4  sm:mx-auto h-full flex flex-col justify-between min-h-97">
      <div className="flex flex-col md:flex-row my-10 sm:my-14 h-full items-center">
        <div className="w-full flex justify-center md:justify-end md:min-h-59">
          <span className="md:mb-0 mb-5 mt-6 md:mr-5 lg:mr-8 text-6xl sm:text-100px text-button-bg opacity-50 font-bold uppercase sm:text-right text-center">
            {t('login.login')}
          </span>
        </div>
        <div className="w-full">
          <form
            onSubmit={(e) => onFormSubmit(e)}
            className="bg-primary rounded-xl flex flex-col py-5 sm:py-9 px-7 sm:w-97"
          >
            <div className="flex flex-col w-full relative mb-8">
              <span className="absolute top-4 ie:top-5 left-4">
                <img className="w-7 h-6" src={emailIcon} />
              </span>
              <input
                className="rounded-lg w-full h-14 lg:h-15 px-14 text-xl outline-none"
                placeholder={t('login.email_address')}
                value={email}
                onChange={(value) => handleChangeInput(value)}
                type="email"
              />
            </div>
            <div
              className="flex justify-end items-center w-full mt-2 text-white"
              onClick={() => handleSubmit()}
            >
              <span className="text-xl h-full flex items-center mt-1 uppercase">
                {t('login.next')}
              </span>
              <img
                className="w-10 h-10 sm:w-14 sm:h-14 ml-5 cursor-pointer"
                onClick={() => handleSubmit()}
                src={nextIcon}
              />
            </div>
          </form>
          <ErrorPopup
            open={openPopup}
            content={error}
            handleClosePopup={() => setOpenPopup(false)}
          />
        </div>
      </div>
      <div className="w-full">
        <p className="w-full sm:w-10/12 mb-16 sm:mb-4 text-button-bg">
          {parse(t('main_page.footer_note'))}
        </p>
      </div>
    </div>
  )
}
export default LoginLayout
