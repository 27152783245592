import axios from 'axios'
import getConfig from './getConfig'
import userAuth from './userAuth'

const { authToken } = userAuth.getAuth()

const config = getConfig()

let instance = axios.create({
  baseURL: config.baseApiUrl,
  headers: {
    'Content-Type': 'application/json',
    Authorization: authToken
  }
})

instance.interceptors.request.use(
  (config) => {
    if (config.data) {
      const haveFile = Object.values(config.data).some(
        (e) => e && e.toString() === '[object File]'
      )
      if (haveFile) {
        config.headers['Content-Type'] = 'multipart/form-data'
      }
    }

    return config
  },
  (error) => Promise.reject(error)
)

instance.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response && error.response.data) {
      return Promise.reject({ ...error.response.data })
    } else {
      return Promise.reject({
        error: true,
        message: 'Error Code 100: No response error from server',
        statusCode:
          error && error.request && error.request.status
            ? error.request.status
            : '1899'
      })
    }
  }
)

export default instance
