import React from 'react'

function CommentItem({ content, creator }) {
  return (
    <div className="mt-3">
      <span className="text-lg text-primary mr-2">{creator}</span>{' '}
      <span className="text-base text-gray">{content}</span>
    </div>
  )
}

export default React.memo(CommentItem)
