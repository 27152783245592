import React from 'react'
import { classNameObject, classNames } from '../../utils'
import CircleArrowLeft from '../Icons/CircleArrowLeft'
function BackButton({ className, onClick }) {
  return (
    <a
      onClick={onClick}
      className={classNames({
        ...classNameObject(className),
        'flex flex-col justify-center items-center cursor-pointer': true
      })}
    >
      <span>
        <CircleArrowLeft />
      </span>
    </a>
  )
}
export default BackButton
