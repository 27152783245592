import React from 'react'
export default function CircleArrowDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 56.68 56.68"
      className="w-9 h-9 sm:w-14 sm:h-14"
    >
      <defs>
        <style>
          {
            '.prefix__cls-1{fill:none;stroke:#b3b8bc;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}'
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <circle className="prefix__cls-1" cx={28.34} cy={28.34} r={27.59} />
          <path
            className="prefix__cls-1"
            d="M42.19 24.22L28.34 38.07 14.49 24.22"
          />
        </g>
      </g>
    </svg>
  )
}
