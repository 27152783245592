import Cookies from 'js-cookie'
const userAuth = {
  getAuth: () => {
    let authToken = Cookies.get('auth-token')
    return {
      isAuthenticated: !!authToken,
      authToken
    }
  },

  setAuth: (args) => {
    Cookies.set('auth-token', args['auth_token'])
  },

  clearAuth: () => {
    Cookies.remove('auth-token')
  }
}

export default userAuth
