import React from 'react'
import LoginLayout from '../components/Login'
function Login() {
  return (
    <div className="h-full flex-1 flex flex-col items-center">
      <LoginLayout />
    </div>
  )
}

export default Login
