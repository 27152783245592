import React from 'react'
export default function PlusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21.46 21.46"
      width="21.46"
      height="21.46"
    >
      <defs>
        <style>
          {
            '.prefix__cls-1{fill:none;stroke:#68737a;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}'
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path className="prefix__cls-1" d="M10.73 1v19.46M1 10.73h19.46" />
        </g>
      </g>
    </svg>
  )
}
