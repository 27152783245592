import React, { useState, useEffect, useRef } from 'react'

const Masonry = ({ brakePoints, children }) => {
  const masonryRef = useRef(null)

  const [columns, setColumns] = useState(brakePoints.length + 1)

  const getColumns = (w) => {
    let cols = 0
    brakePoints.some((item, index) => {
      if (w < item) {
        cols = index + 1
        return true
      }
    })
    return cols === 0 ? brakePoints.length + 1 : cols
  }
  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [columns])

  const onResize = () => {
    const cols = getColumns(masonryRef.current.offsetWidth)
    if (cols !== columns) {
      setColumns(cols)
    }
  }

  const mapChildren = () => {
    let col = []
    const numC = columns
    for (let i = 0; i < numC; i++) {
      col.push([])
    }
    return children.reduce((p, c, i) => {
      p[i % numC].push(c)
      return p
    }, col)
  }

  return (
    <div
      className="flex flex-row justify-center place-content-stretch w-full m-auto"
      ref={masonryRef}
    >
      {mapChildren().map((col, ci) => {
        return (
          <div
            className="flex flex-col justify-start place-content-stretch"
            key={ci}
          >
            {col.map((child, i) => {
              return (
                <div className="min-h-1" key={i}>
                  {child}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}
export default Masonry
