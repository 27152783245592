import React from 'react'
import BackButton from 'components/Button/Back'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
export default function SubmissionAcknowledgement() {
  const history = useHistory()
  const { t, i18n } = useTranslation()
  function handleClickBack() {
    const { language } = i18n
    history.push(`/${language}/add-your-story`)
    return false
  }
  function handleClickBackViewAllPosts() {
    const { language } = i18n
    history.push(`/${language}`)
    return false
  }

  return (
    <div className="px-4 lg:px-0 max-w-101 w-full mx-auto">
      <div className="flex flex-col items-center w-full sm:py-16 pb-16 pt-10 relative ">
        <div className="title relative w-full flex sm:items-center sm:justify-center sm:flex-row flex-col items-start ">
          <div className="text-primary text-3xl sm:text-10 font-bold text-center uppercase leading-none w-full">
            {t('acknowledgement.acknowledgement')}
          </div>
        </div>
        <div className="flex flex-col items-center sm:mt-8 mt-6">
          <div className="main-message text-primary text-2xl sm:text-3xl text-center">
            {t('acknowledgement.submission_submitted')}
          </div>
          <div className="notification shadow-content-wrapper rounded-3xl px-8 py-6 w-full sm:w-9/12  mt-8 text-lg sm:text-xl text-general-gray">
            <div className="mb-3 text-center leading-tight">
              {t('acknowledgement.thank_you')}
            </div>
            <div className="mb-3 text-center leading-tight">
              {t('acknowledgement.thank_you_1')}
              <br />
              {t('acknowledgement.thank_you_2')}
            </div>
            <div className="text-center leading-tight">
              {parse(t('acknowledgement.thank_you_3'))}
            </div>
            <div className="button-container mt-5">
              <div className="button-wrapper flex justify-center">
                <button
                  className="text-base sm:text-xl rounded-full text-white  border font-extrabold focus:ring-0 outline-none focus:outline-none pb-1.5 pt-2 px-4 uppercase transition-all duration-500 ease-in-out min-h-post-button min-w-post-button bg-primary border-primary"
                  onClick={() => handleClickBackViewAllPosts()}
                >
                  {t('acknowledgement.back_to_view_posts')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
