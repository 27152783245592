import React from 'react'
import { classNames, classNameObject } from '../../utils/index'

function LoginButton({ title, note, onClick, className }) {
  return (
    <div
      onClick={onClick}
      className={classNames({
        ...classNameObject(className),
        'bg-white flex flex-col items-center justify-center border-2 border-primary px-5 sm:px-10 py-3 cursor-pointer rounded-full': true
      })}
    >
      <p className="-mb-1 text-2xl sm:text-3xl text-gray uppercase">{title}</p>
      <p className="-mb-1 text-sm sm:text-base text-gray text-center">{note}</p>
    </div>
  )
}
export default LoginButton
