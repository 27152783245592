export function isBlankString(stringValue) {
  return !stringValue || stringValue.trim().length === 0
}

export function isEmptyObject(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false
    }
  }

  return JSON.stringify(obj) === JSON.stringify({})
}

export function isEmptyErrors(errorObj) {
  if (isEmptyObject(errorObj)) {
    return true
  }
  const checkerResult = Object.values(errorObj).reduce((prev, value) => {
    return [...prev, value]
  }, [])
  return (
    checkerResult.length === 0 ||
    (checkerResult.length === 1 &&
      (checkerResult[0] === false || isBlankString(checkerResult[0])))
  )
}
