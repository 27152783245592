import React, { useState, useReducer, useEffect } from 'react'
import PostListing from '../components/PostListing'
import HeaderButtonsGroup from '../components/HeaderButtonsGroup'
import PostDetail from '../containers/PostDetail'
import axios from '../utils/axios'
import { useHistory } from 'react-router-dom'
import Loading from '../components/Loading/index'
import userAuth from '../utils/userAuth'
import Popup from '../components/Popup/index'
import Button from '../components/Button/index'
import { useTranslation } from 'react-i18next'

const ALL_POSTS = 1
const MY_POSTS = 2
const POST_DETAIL = 3

function Home({ t }) {
  const history = useHistory()
  const [currentState, setCurrentState] = useState(0)

  const { i18n } = useTranslation()
  const { language } = i18n

  const isClosed = false

  function reducer(state, action) {
    const updatedValues = state
    if ('section' in action) {
      updatedValues.section = action.section
    }
    if ('posts' in action) {
      updatedValues.posts = action.posts
    }
    if ('selectedPostUuid' in action) {
      updatedValues.selectedPostUuid = action.selectedPostUuid
    }
    if ('selectedDeleteUuid' in action) {
      updatedValues.selectedDeleteUuid = action.selectedDeleteUuid
    }
    if ('page' in action) {
      updatedValues.page = action.page
    }
    if ('total' in action) {
      updatedValues.total = action.total
    }
    if ('openDeletePopup' in action) {
      updatedValues.openDeletePopup = action.openDeletePopup
    }
    if ('loading' in action) {
      updatedValues.loading = action.loading
    }
    if ('render' in action && !!action.render) {
      setCurrentState(1 - currentState)
    }
    return updatedValues
  }

  const [values, dispatch] = useReducer(reducer, {
    section: ALL_POSTS,
    posts: [],
    selectedPostUuid: null,
    selectedDeleteUuid: null,
    openDeletePopup: false,
    page: 1,
    total: 0,
    loading: true,
    render: false
  })

  const {
    section,
    posts,
    selectedPostUuid,
    selectedDeleteUuid,
    page,
    total,
    loading,
    openDeletePopup
  } = values

  useEffect(() => {
    let isMounted = true
    if (section === MY_POSTS) {
      fetchMyPosts(isMounted)
    } else if (section === ALL_POSTS) {
      fetchAllPosts(isMounted)
    }
    return () => {
      isMounted = false
    }
  }, [section])

  async function fetchAllPosts(isMounted) {
    dispatch({
      loading: true,
      render: true
    })
    try {
      const resp = await axios.get('/posts', {
        params: {
          per_page: 10,
          page: page
        }
      })
      const { records, next_page, total } = resp
      dispatch({
        posts: page === 1 ? records : [...posts, ...records],
        page: next_page,
        loading: false,
        total: total,
        render: isMounted
      })
    } catch (error) {
      if (error && error.code === 401) {
        userAuth.clearAuth()
        window.location.reload()
      }
    }
  }

  async function fetchMyPosts(isMounted) {
    dispatch({
      loading: true,
      render: true
    })
    try {
      const resp = await axios.get('/posts/my_posts', {
        params: {
          per_page: 10,
          page: page
        }
      })
      const { records, next_page, total } = resp
      dispatch({
        posts: page === 1 ? records : [...posts, ...records],
        page: next_page,
        loading: false,
        total: total,
        render: isMounted
      })
    } catch (error) {
      if (error && error.code === 401) {
        userAuth.clearAuth()
        window.location.reload()
      }
    }
  }

  function onLoadMore() {
    if (!!page && !loading) {
      dispatch({ loading: true, render: true })
      if (section === MY_POSTS) {
        fetchMyPosts(true)
      } else if (section === ALL_POSTS) {
        fetchAllPosts(true)
      }
    }
  }
  function handleClickAddStory() {
    history.push(`/${language}/add-your-story`)
  }

  function handleClickLogin() {
    history.push(`/${language}/login`)
  }

  function handleSelectPost(uuid) {
    dispatch({ selectedPostUuid: uuid, section: POST_DETAIL, render: true })
  }

  function handleEditPost(uuid, language) {
    history.push(`/${language}/add-your-story/${uuid}`)
  }

  function handleDeletePost(uuid) {
    dispatch({ openDeletePopup: true, selectedDeleteUuid: uuid, render: true })
  }
  async function deletePost() {
    try {
      const resp = await axios.delete(`/posts/${selectedDeleteUuid}`)
      const { success } = resp
      if (success) {
        const newPosts = posts.filter(
          (post) => post.uuid !== selectedDeleteUuid
        )
        dispatch({
          loading: false,
          posts: newPosts,
          section: MY_POSTS,
          page: 1,
          total: total - 1,
          selectedPostUuid: null,
          openDeletePopup: false,
          render: true
        })
      }
    } catch (error) {
      console.log('error :>> ', error)
    }
  }

  return (
    <div className="h-full py-10">
      <div className="px-5 sm:p-10 ">
        <HeaderButtonsGroup
          t={t}
          isClosed={isClosed}
          showBackButton={section !== ALL_POSTS}
          onClickBackButton={() =>
            dispatch({
              section: ALL_POSTS,
              page: 1,
              selectedPostUuid: null,
              render: true
            })
          }
          onClickMyPost={() =>
            dispatch({
              section: MY_POSTS,
              page: 1,
              selectedPostUuid: null,
              render: true
            })
          }
          onClickAddPost={handleClickAddStory}
          onClickLogin={handleClickLogin}
          isMyPostActive={section === MY_POSTS}
        />
      </div>

      <div className="px-0 sm:px-10">
        {selectedPostUuid ? (
          <PostDetail
            uuid={selectedPostUuid}
            t={t}
            onEditPost={handleEditPost}
            onDeletePost={handleDeletePost}
          />
        ) : (
          <PostListing
            data={posts}
            isClosed={isClosed}
            onLoadMore={onLoadMore}
            onSelectPost={handleSelectPost}
            onEditPost={handleEditPost}
            onDeletePost={handleDeletePost}
            canLoadMore={!!page && posts.length < total}
            loading={loading}
            t={t}
          />
        )}
      </div>
      <Popup
        open={openDeletePopup}
        handleClosePopup={() =>
          dispatch({
            openDeletePopup: false,
            selectedDeleteUuid: null,
            render: true
          })
        }
      >
        <div className="p-2 flex flex-col items-center justify-between">
          <div>
            <p className="text-gray sm:text-lg text-center">
              {t('my_post.delete_post_confirmation')}
            </p>
          </div>
          <div className="flex mt-5">
            <Button
              title={t('login.close')}
              className={'h-9 min-w-25 mr-4'}
              classNameText={'font-bold'}
              onClick={() =>
                dispatch({
                  openDeletePopup: false,
                  selectedDeleteUuid: null,
                  render: true
                })
              }
            />
            <Button
              className={'h-9 min-w-25'}
              classNameText={'font-bold'}
              title={t('my_post.ok')}
              onClick={deletePost}
            />
          </div>
        </div>
      </Popup>
      <Loading loading={loading} />
    </div>
  )
}

export default Home
