import React, { useState, useEffect } from 'react'

export default function TextArea(props) {
  const {
    name: inputName,
    className,
    placeholder,
    onTextChange,
    error,
    defaultValue
  } = props

  const [currentState, setCurrentState] = useState(0)
  function handleChange(event) {
    const value = event.target.value
    onTextChange(value)
  }
  useEffect(() => {
    setCurrentState(1 - currentState)
  }, [error])

  return (
    <>
      <textarea
        name={inputName}
        onChange={handleChange.bind(this)}
        onBlur={handleChange.bind(this)}
        value={defaultValue}
        className={className}
        placeholder={placeholder}
      >
        {defaultValue}
      </textarea>
    </>
  )
}
