import React, { useCallback } from 'react'
import Post from '../Post'
import Masonry from '../Masonry'
import LoadMoreButton from '../Button/LoadMore'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import noPostIcon from '../../images/icons/no_post_icon.png'
import { useTranslation } from 'react-i18next'
dayjs.extend(relativeTime)

function PostListing({
  onLoadMore,
  data,
  canLoadMore,
  onSelectPost,
  onEditPost,
  onDeletePost,
  t,
  loading,
  isClosed
}) {
  const { i18n } = useTranslation()
  const handleSelectPost = useCallback((uuid) => {
    onSelectPost(uuid)
  }, [])
  const handleEditPost = useCallback((uuid) => {
    if (!isClosed) {
      onEditPost(uuid, i18n.language)
    }
  }, [])
  const handleDeletePost = useCallback((uuid) => {
    if (!isClosed) {
      onDeletePost(uuid)
    }
  }, [])

  return (
    <div className="p-10 m-auto">
      {data && data.length < 1 && !loading && !isClosed && (
        <div className="p-6 flex flex-col justify-center items-center">
          <img src={noPostIcon} />
          <p className="text-2xl sm:text-3xl font-bold text-primary mt-5">
            {t('main_page.no_posts_yet')}
          </p>
          <p className="text-base sm:text-2xl font-light text-gray text-center">
            {' '}
            {t('main_page.post_an_image')}
          </p>
        </div>
      )}
      <Masonry brakePoints={[600, 850]}>
        {data.map((itemData, index) => (
          <Post
            key={itemData.uuid}
            onClick={handleSelectPost}
            onEdit={handleEditPost}
            onDelete={handleDeletePost}
            data={itemData}
            dayjs={dayjs}
            index={index}
          />
        ))}
      </Masonry>
      <div className="flex justify-center mt-8">
        {canLoadMore && data.length > 0 && (
          <LoadMoreButton onClick={onLoadMore} />
        )}
      </div>
    </div>
  )
}
export default PostListing
