import React, { useState, useEffect } from 'react'
import { classNames } from 'utils'
import { useTranslation } from 'react-i18next'

export default function Agreement({
  defaultValue,
  onToggleAgreement,
  error,
  errorText
}) {
  const { t } = useTranslation()
  const [checkedStatus, setCheckedStatus] = useState(false)
  function handleToggle() {
    const status = !checkedStatus
    onToggleAgreement(status ? 'READY' : 'PREPARE')
    setCheckedStatus(status)
  }
  useEffect(() => {
    setCheckedStatus(!!defaultValue)
  }, [defaultValue])
  return (
    <>
      <div className="agreement-wrapper flex">
        <div className="checkbox-container mr-3">
          <div
            className={classNames({
              'w-6 h-6 border-2 rounded-full cursor-pointer flex items-center justify-center': true,
              'border-gray-border bg-white': !checkedStatus,
              'border-primary bg-primary text-white': checkedStatus
            })}
            onClick={handleToggle.bind(this)}
          >
            {checkedStatus && (
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            )}
          </div>
        </div>
        <div className="text-content-container flex flex-col flex-1">
          <div
            className="text-wrapper text-sm mt-1 text-general-gray cursor-pointer"
            onClick={handleToggle.bind(this)}
          >
            <p>{t('add_your_story.agreement')}</p>
            {error && <p className="text-sm text-primary">{errorText}</p>}
          </div>
          <div className="text-wrapper mt-4">
            <p className="text-sm font-bold text-primary">
              {t('terms_and_conditions.title')}
            </p>
            <p className="text-sm font-light text-gray">
              {t('terms_and_conditions.term_1')}
            </p>
            <p className="text-sm font-light text-gray">
              {t('terms_and_conditions.term_2')}
            </p>
            <p className="text-sm font-light text-gray">
              {t('terms_and_conditions.term_3')}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
