import React, { useImperativeHandle } from 'react'
import { classNames } from 'utils'

export default React.forwardRef(function ButtonUpload(
  {
    className,
    representButtonName,
    representIconSrc,
    note,
    inputName,
    accept,
    type,
    onSelectFile,
    onValidateFile,
    onShowError
  },
  ref
) {
  const uploadFile = React.createRef(null)
  function handleChangeFile(event) {
    const fileUploaded = event.target.files[0]
    if (fileUploaded) {
      if (onValidateFile(fileUploaded)) {
        onShowError(null)
        onSelectFile({ file: fileUploaded, type })
      } else {
        onShowError(type)
      }
    }
  }

  useImperativeHandle(ref, () => ({
    openExplorer: () => {
      uploadFile.current.click()
    },
    clearInput: () => {
      uploadFile.current.value = ''
    }
  }))

  function handleOpenChooseFile() {
    uploadFile.current.click()
  }

  return (
    <div
      className={classNames({
        'button-uploader-container': true,
        [className]: true
      })}
    >
      <div className="button-uploader-wrapper">
        <input
          type="file"
          ref={uploadFile}
          onChange={handleChangeFile}
          className="hidden"
          accept={accept}
          name={inputName}
        />
        <div className="represent-button-container">
          <div
            className="represent-button-wrapper flex border-2 rounded-full  py-2 border-primary items-center cursor-pointer justify-center"
            onClick={handleOpenChooseFile.bind(this)}
          >
            <div className="mr-2">
              <img src={representIconSrc} className="w-5" />
            </div>
            <div className="text-sm uppercase text-general-gray pt-1 ie:pt-0 font-bold">
              {representButtonName}
            </div>
          </div>
        </div>

        <div className="mt-1.5">
          <div className="represent-button-wrapper flex justify-center items-center">
            <div className="text-xs text-general-gray">{note}</div>
          </div>
        </div>
      </div>
    </div>
  )
})
