import React from 'react'

export default function CircleArrowLeft() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 56.68 56.68"
      className="w-9 h-9 sm:w-14 sm:h-14"
    >
      <defs>
        <style>
          {
            '.CircleArrowLeft_prefix__cls-1{fill:none;stroke:#b3b8bc;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}'
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <circle
            className="CircleArrowLeft_prefix__cls-1"
            cx={28.34}
            cy={28.34}
            r={27.59}
            transform="rotate(-76.72 28.34 28.338)"
          />
          <path
            className="CircleArrowLeft_prefix__cls-1"
            d="M32.46 42.19L18.61 28.34l13.85-13.85"
          />
        </g>
      </g>
    </svg>
  )
}
