import React from 'react'
import { useTranslation } from 'react-i18next'
import CircleArrowDown from '../Icons/CircleArrowDown'
function LoadMoreButton({ onClick }) {
  const { t } = useTranslation()
  return (
    <a
      onClick={onClick}
      className="flex flex-col justify-center items-center cursor-pointer"
    >
      <span>
        <CircleArrowDown />
      </span>
      <p className="mt-4 text-lg text-gray uppercase">
        {t('main_page.load_more')}
      </p>
    </a>
  )
}

export default LoadMoreButton
