import React, {
  useState,
  useReducer,
  useRef,
  useCallback,
  useEffect
} from 'react'
import ButtonUpload from 'components/ButtonUpload'
import PhotoBooth from 'images/icons/photo_booth.png'
import Camera from 'images/icons/camera.png'
import Remove from 'images/icons/remove.png'
import Replace from 'images/icons/replace.png'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

function MediaUploader({ onMediaSelect, defaultMedia }) {
  const { t } = useTranslation()
  const [currentState, setCurrentState] = useState(0)
  const imageUploader = useRef(null)
  const videoUploader = useRef(null)
  const acceptImageTypes = 'image/png,image/jpg,image/jpeg,image/gif'
  const acceptVideoTypes =
    'video/mp4,video/x-m4v,video/quicktime,video/x-msvideo,video/x-ms-wmv,video/x-flv,application/x-mpegURL,video/webm,video/ogg'

  function reducer(state, action) {
    let updatedValues = state
    if ('id' in action) {
      updatedValues.id = action.id
    }
    if ('file' in action) {
      updatedValues.file = action.file
    }
    if ('type' in action) {
      updatedValues.type = action.type
    }
    if ('defaultMediaValue' in action) {
      updatedValues.defaultMediaValue = action.defaultMediaValue
    }
    if ('errorMessage' in action) {
      updatedValues.error = action.error
    }
    if ('reset' in action) {
      updatedValues = {
        id: null,
        file: {},
        type: null
      }
    }
    if (action.render) {
      setCurrentState(1 - currentState)
    }

    return updatedValues
  }
  const [media, dispatch] = useReducer(reducer, {
    id: null,
    file: {},
    type: null,
    defaultMediaValue: defaultMedia
  })
  const { type, file, id, defaultMediaValue } = media

  useEffect(() => {
    dispatch({
      defaultMediaValue: defaultMedia,
      render: true
    })
  }, [defaultMedia])

  function validateImage(image) {
    if (image) {
      const limitFileSize = 10 * 1024 * 1024
      const limitContentTypes = acceptImageTypes.split(',')
      const size = image.size
      const contentType = image.type
      return size <= limitFileSize && limitContentTypes.includes(contentType)
    } else {
      return false
    }
  }

  function validateVideo(video) {
    if (video) {
      const limitFileSize = 30 * 1024 * 1024
      const limitContentTypes = acceptVideoTypes.split(',')
      const size = video.size
      const contentType = video.type
      return size <= limitFileSize && limitContentTypes.includes(contentType)
    } else {
      return false
    }
  }

  function handleSelectFile({ file, type }) {
    const variable = {
      file: file,
      type: type,
      id: uuidv4()
    }

    onMediaSelect(variable)
    dispatch({
      ...variable,
      ...{ render: true }
    })
  }

  function handleRemoveFile() {
    if (media.type === 'IMAGE' || (defaultMediaValue || {}).type === 'image') {
      if (!!defaultMediaValue && !!defaultMediaValue.url) {
        dispatch({ defaultMediaValue: null, render: true })
      }
      imageUploader.current.clearInput()
    } else if (
      media.type === 'VIDEO' ||
      (defaultMediaValue || {}).type === 'video'
    ) {
      if (!!defaultMediaValue && !!defaultMediaValue.url) {
        dispatch({ defaultMediaValue: null, render: true })
      }
      videoUploader.current.clearInput()
    }
    const variable = { id: null, file: null, type: null }

    onMediaSelect(variable)
    dispatch({
      ...variable,
      ...{ render: true }
    })
  }

  function handleReOpenExplorer() {
    if (media.type === 'IMAGE' || (defaultMediaValue || {}).type === 'image') {
      imageUploader.current.openExplorer()
    }
    if (media.type === 'VIDEO' || (defaultMediaValue || {}).type === 'video') {
      videoUploader.current.openExplorer()
    }
  }

  function toolbarPreview() {
    return (
      <>
        <div className="absolute right-3 z-50">
          <div className="toolbar-wrapper flex justify-center flex-col sm:flex-row">
            <div
              className="replace-tool w-9 h-9 rounded-full bg-general-gray flex justify-center items-center mt-3 shadow-md cursor-pointer"
              onClick={handleReOpenExplorer.bind(this)}
            >
              <img src={Replace} className="w-4" />
            </div>
            <div
              className="removal-tool  w-9 h-9  rounded-full bg-general-gray flex justify-center items-center mt-3 ml-2 shadow-md cursor-pointer"
              onClick={handleRemoveFile.bind(this)}
            >
              <img src={Remove} className="w-4" />
            </div>
          </div>
        </div>
      </>
    )
  }

  function previewVideo(videoSrc) {
    return (
      <div className="relative video-wrapper flex justify-center max-w-preview-media rounded-2xl overflow-hidden sm:w-9/12 w-full shadow-preview">
        {toolbarPreview()}
        <video
          src={videoSrc}
          controls
          className="outline-none focus:outline-none w-full"
        />
      </div>
    )
  }

  function previewImage(imgSrc) {
    return (
      <div className="relative image-wrapper max-w-preview-media rounded-3xl overflow-hidden w-full sm:w-9/12 shadow-preview">
        {toolbarPreview()}
        <img src={imgSrc} className="w-full" />
      </div>
    )
  }

  function onShowError(type) {
    let value = {
      render: true,
      type: type
    }
    if (type === 'IMAGE') {
      value['text'] = t('add_your_story.error_limit_image_size')
    } else if (type === 'VIDEO') {
      value['text'] = t('add_your_story.error_limit_video_size')
    } else {
      value['text'] = t('add_your_story.error_limit_file_size')
    }
    dispatchErr(value)
  }

  function showError() {
    const { type, text } = errorMessage
    if (type) {
      return (
        <div className="container mt-5 text-center">
          <div className="wrapper text-primary">{text}</div>
        </div>
      )
    }
    return <></>
  }

  function errReducer(state, action) {
    const updatedValues = state
    if ('type' in action) {
      updatedValues.type = action.type
    }
    if ('text' in action) {
      updatedValues.text = action.text
    }
    if ('render' in action && !!action.render) {
      setCurrentState(1 - currentState)
    }
    return updatedValues
  }

  const [errorMessage, dispatchErr] = useReducer(errReducer, {
    text: null,
    type: null
  })

  return (
    <>
      <div className="upload-buttons-wrapper flex justify-center flex-col sm:flex-row sm:w-full w-9/12 mx-auto">
        <ButtonUpload
          className="mx-0 sm:mx-4 min-w-41 min-h-8 mt-4"
          representButtonName={t('add_your_story.add_image_gif')}
          representIconSrc={PhotoBooth}
          note={t('add_your_story.limit_image')}
          accept={acceptImageTypes}
          type="IMAGE"
          onValidateFile={validateImage}
          onSelectFile={handleSelectFile}
          ref={imageUploader}
          onShowError={onShowError}
        />
        <ButtonUpload
          className="mx-0 sm:mx-4 min-w-41 min-h-8 mt-4"
          representButtonName={t('add_your_story.add_video')}
          representIconSrc={Camera}
          note={t('add_your_story.limit_video')}
          accept={acceptVideoTypes}
          type="VIDEO"
          onValidateFile={validateVideo}
          onSelectFile={handleSelectFile}
          ref={videoUploader}
          onShowError={onShowError}
        />
      </div>
      {showError()}
      {!!type && (
        <div className="preview-media flex justify-center mt-10">
          {type === 'IMAGE' && previewImage(URL.createObjectURL(file))}
          {type === 'VIDEO' && previewVideo(URL.createObjectURL(file))}
        </div>
      )}
      {!type && !!defaultMediaValue && (
        <div className="preview-media flex justify-center mt-10">
          {defaultMediaValue.type === 'image' &&
            previewImage(defaultMediaValue.url)}
          {defaultMediaValue.type === 'video' &&
            previewVideo(defaultMediaValue.url)}
        </div>
      )}
    </>
  )
}
function equalChange(prevProp, nextProp) {
  return (
    prevProp.assetId === nextProp.assetId &&
    prevProp.defaultMedia === nextProp.defaultMedia
  )
}

export default React.memo(MediaUploader, equalChange)
