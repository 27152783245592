import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import mainLogo from '../../images/prudential-logo.png'
import contact from '../../images/contact.svg'
import vietnam from '../../images/vietnam.svg'
import indonesia from '../../images/indonesia.svg'
import usa from '../../images/usa.svg'
import china from '../../images/china.svg'
import Select from 'react-select'
import HamburgerMenu from 'react-hamburger-menu'
import { classNames } from '../../utils'
import { useLocation } from 'react-router-dom'
import parse from 'html-react-parser'

function formatOptionLabel({ icon, language }) {
  return (
    <span className="flex item-center border-none text-sm">
      <img className="w-5 h-5 mx-2" src={icon} />
      <p>{language}</p>
    </span>
  )
}

function Header({ locale }) {
  const { t } = useTranslation()
  const [state, setState] = useState({ open: false })
  const [language, setLanguages] = useState(null)
  const options = [
    { value: 'en', language: 'English', icon: usa },
    { value: 'id', language: 'Bahasa Indonesia', icon: indonesia },
    { value: 'cn', language: '中文简体', icon: china },
    { value: 'zh', language: '繁體中文', icon: china },
    { value: 'vi', language: 'Tiếng Việt', icon: vietnam }
  ]

  useEffect(() => {
    const option = options.find((option) => {
      return option.value === locale
    })
    setLanguages(option)
  }, [])

  const customStyles = {
    control: () => ({
      boxShadow: '0 0px 8px 2px rgba(0, 0, 0, 0.2)',
      border: 'none',
      borderRadius: '5px',
      height: 30,
      minHeight: 30,
      display: 'flex',
      justifyContent: 'center',
      justifyItems: 'center'
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: 0
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none'
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : '#68737A',
      cursor: 'pointer'
    })
  }

  function handleClick() {
    setState({ open: !state.open })
  }
  let location = useLocation()

  function handleChangeLanguage(option) {
    let newPath = location.pathname
    if (locale) {
      newPath = newPath.replace(`/${locale}`, '')
    }
    newPath = `/${option.value}${newPath}`
    window.location.href = newPath
  }

  const homeRoute = locale ? `/${locale}` : '/'
  const locales = {
    en: 'en',
    cn: 'sc',
    zh: 'tc',
    vi: 'vn',
    id: 'id'
  }

  const pruEventLocale = locales[locale]
  console.log(typeof process.env.REACT_APP_HOST_TYPE)

  const soYouLink =
    process.env.REACT_APP_HOST_TYPE === 'production'
      ? `https://www.soyouthinkyoucandance.pruevent-asia.com/${locale}`
      : `https://soyouthinkyoucandance.projectwebby.com/${locale}`
  return (
    <div className="w-full flex justify-center">
      <div className="px-0 sm:px-6 max-w-screen-xl w-full">
        <div className="w-full shadow-header ">
          <div className="relative flex">
            <span className="flex justify-center items-center mt-4 sm:mt-0 sm:mb-0 mb-10 sm:absolute sm:block -left-21 top-4 sm:z-10 w-full sm:w-auto ">
              <img className="w-48 sm:w-56 h-auto" src={mainLogo} />
            </span>
            <HamburgerMenu
              isOpen={state.open}
              menuClicked={() => handleClick()}
              width={31}
              height={25}
              strokeWidth={4}
              rotate={0}
              color="rgb(239, 26, 47)"
              borderRadius={10}
              animationDuration={0.2}
              className="absolute right-5 top-5 sm:hidden"
            />
            <div
              className={classNames({
                'sm:flex py-2 pl-3 sm:pr-6 sm:flex-col w-full justify-center items-center border border-gray-border rounded-lg shadow-lg sm:shadow-none sm:border-none sm:bg-none top-20 sm:top-0  bg-white px-3 flex-col-reverse z-10 sm:z-0': true,
                'absolute sm:relative ': state.open,
                'hidden ': !state.open
              })}
            >
              <div className="relative flex flex-row sm:justify-end w-full pl-2 sm:pl-0 sm:pr-5 sm:mt-1 sm:mb-20 text-gray my-6 justify-between">
                <Select
                  value={language}
                  formatOptionLabel={formatOptionLabel}
                  styles={customStyles}
                  options={options}
                  className="w-56 mr-2 cursor-pointer"
                  isSearchable={false}
                  onChange={(option) => handleChangeLanguage(option)}
                />
                <a
                  className="flex text-sm currsor-pointer"
                  href="mailto:prumdrt2021@mci-group.com"
                >
                  <span className="underline pr-3 sm:px-2">
                    {t('privacy_notice_footer.contact_us')}
                  </span>
                  <img className="w-5 h-5" src={contact} />
                </a>
              </div>
              <div className="w-full flex sm:flex-row justify-end sm:items-center font-bold text-md sm:py-4 px-0 flex-col items-start text-gray">
                <a
                  className="cursor-pointer text-center px-2 hover:text-primary lg:px-4 py-2 uppercase"
                  href={`http://pruevent-asia.com/prumdrt2021/${pruEventLocale}`}
                  rel="noreferrer"
                >
                  {parse(t('privacy_notice_footer.home'))}
                </a>
                <a
                  className="cursor-pointer text-center px-2 hover:text-primary lg:px-4 py-2 uppercase"
                  href={`http://pruevent-asia.com/prumdrt2021/#/${pruEventLocale}/agenda`}
                  rel="noreferrer"
                >
                  {parse(t('privacy_notice_footer.agenda'))}
                </a>
                <a
                  className="cursor-pointer text-center px-2 hover:text-primary lg:px-4 py-2 uppercase"
                  href={`http://pruevent-asia.com/prumdrt2021/#/${pruEventLocale}/speakers`}
                  rel="noreferrer"
                >
                  {parse(t('privacy_notice_footer.speakers'))}
                </a>
                <a
                  className="cursor-pointer text-center px-2 hover:text-primary lg:px-4 py-2"
                  href={`http://pruevent-asia.com/prumdrt2021/#/${pruEventLocale}/wedo`}
                  rel="noreferrer"
                >
                  {parse(t('privacy_notice_footer.we_do_activities'))}
                </a>
                <a
                  className="cursor-pointer text-center px-2 hover:text-primary lg:px-4 py-2"
                  href={`https://prumdrt2021.pruevent-asia.com/${pruEventLocale}/runtomdrt`}
                  rel="noreferrer"
                >
                  {parse(t('privacy_notice_footer.run_to_mdrt'))}
                </a>
                <a
                  className="cursor-pointer text-center  w-auto sm:w-32 align-center px-2 lg:px-4 py-2 text-primary"
                  href={homeRoute}
                >
                  {parse(t('main_page.your_voice_your_story'))}
                </a>
                <a
                  className="cursor-pointer text-center w-auto sm:w-36 align-center px-2 lg:px-4 py-2 hover:text-primary"
                  href={soYouLink}
                  rel="noreferrer"
                >
                  {parse(t('main_page.so_you_think_you_can_dance'))}
                </a>
                <a
                  className="cursor-pointer w-40 text-center px-2 lg:px-4 py-1 rounded-full text-white bg-primary ml-2 sm:ml-0 uppercase"
                  href={`https://www.registration.pruevent-asia.com/register/${
                    locale === 'vi' ? 'vn' : locale
                  }`}
                  rel="noreferrer"
                >
                  {parse(t('privacy_notice_footer.register_now'))}
                </a>
                <a
                  className="cursor-pointer text-center px-3 hover:text-primary lg:px-5 py-2 uppercase"
                  href={`http://pruevent-asia.com/prumdrt2021/#/${pruEventLocale}/faq`}
                  rel="noreferrer"
                >
                  {parse(t('privacy_notice_footer.faq'))}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
