import React from 'react'
import { classNames, classNameObject } from '../../utils/index'

function Button({ title, Icon, onClick, className, classNameText, active }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames({
        ...classNameObject(className),
        'bg-primary': active,
        'bg-white flex items-center justify-center border border-primary px-5 py-3 cursor-pointer rounded-full outline-none focus:outline-none': true
      })}
    >
      {Icon && (
        <span className="mr-3">
          <Icon active={active} />
        </span>
      )}
      {title && (
        <p
          className={classNames({
            'text-white': active,
            'text-gray': !active,
            '-mb-1  ie:mb-0': true,
            ...classNameObject(classNameText)
          })}
        >
          {title}
        </p>
      )}
    </button>
  )
}
export default Button
