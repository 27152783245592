import React, { useState, useReducer, useEffect } from 'react'
import { classNames, formatErrors } from 'utils'
import { isBlankString, isEmptyErrors } from 'utils/validate'
import MediaUploader from './MediaUploader'
import TextArea from './TextArea'
import Agreement from './Agreement'
import BackButton from 'components/Button/Back'
import axios from 'utils/axios'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import userAuth from '../../utils/userAuth'
import browser from 'browser-detect'
import useDimensions from 'react-cool-dimensions'
import { ResizeObserver } from '@juggle/resize-observer'

const STATUS_UPLOAD_STORY = Object.freeze({
  PREPARE: 0,
  READY: 10,
  UPLOADING: 20,
  SUCCESS: 30
})

export default function AddYourStoryPage({ match }) {
  const currentBrowser = browser()
  const { observe } = useDimensions({
    onResize: ({ observe, width, height }) => {
      if (currentBrowser.name === 'ie') {
        resizeIEForm({
          width: width,
          height: height
        })
      }
    },
    polyfill: ResizeObserver
  })

  function resizeIEForm({ width, height }) {
    try {
      document.getElementById('main_form').style.height = `${height + 52}px`
    } catch (e) {
      console.log('Error Resize: ', e)
    }
  }
  const { uuid } = match.params
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const [currentState, setCurrentState] = useState(0)
  function reducer(state, action) {
    const updatedValues = state
    if ('id' in action) {
      updatedValues.id = action.id
    }
    if ('media' in action) {
      updatedValues.media = action.media
    }
    if ('defaultMedia' in action) {
      updatedValues.defaultMedia = action.defaultMedia
    }
    if ('content' in action) {
      updatedValues.content = action.content
      if (isBlankString(updatedValues.content)) {
        updatedValues.errors.content = t('add_your_story.caption_error_blank')
      } else {
        updatedValues.errors.content = ''
      }
    }
    if ('media_type' in action) {
      updatedValues.media_type = action.media_type
        ? action.media_type.toLowerCase()
        : null
    }
    if ('agreement' in action) {
      updatedValues.agreement = action.agreement
      updatedValues.errors.agreement = !action.agreement
    }
    if ('status' in action) {
      updatedValues.status = action.status
    }
    if ('loading' in action) {
      updatedValues.loading = action.loading
    }
    if ('errors' in action) {
      updatedValues.errors = { ...updatedValues.errors, ...action.errors }
    }
    if (action.render) {
      setCurrentState(1 - currentState)
    }
    return updatedValues
  }

  const [postAttribute, dispatch] = useReducer(reducer, {
    id: null,
    media: '',
    defaultMedia: null,
    content: null,
    agreement: false,
    media_type: null,
    status: STATUS_UPLOAD_STORY['PREPARE'],
    errors: {}
  })

  const {
    content,
    defaultMedia,
    media_type,
    media,
    status,
    errors,
    agreement
  } = postAttribute

  useEffect(() => {
    let isMounted = true
    if (!!uuid) {
      fetchData(isMounted)
    }
    return () => {
      isMounted = false
    }
  }, [])

  async function fetchData(isMounted) {
    try {
      const resp = await axios.get(`/posts/${uuid}/edit`)
      const { post } = resp
      dispatch({
        defaultMedia: post.media
          ? {
              url: post.media.url,
              type: post.media_type
            }
          : '',
        content: post.content,
        media_type: post.media_type,
        loading: false,
        render: isMounted
      })
    } catch (error) {
      if (error && error.code === 403) {
        window.location.href = `/${language}`
      }
    }
  }

  function handleMediaChange({ file, type }) {
    dispatch({ media: file, media_type: type, render: true })
  }
  function handleChangeText(text) {
    dispatch({ content: text, render: true })
  }
  function handleToggleAgreement(status) {
    dispatch({
      content: postAttribute.content,
      agreement: !agreement,
      status: STATUS_UPLOAD_STORY[status],
      render: true
    })
  }

  async function submitResult() {
    if (status === STATUS_UPLOAD_STORY['UPLOADING']) {
      return null
    }
    if (!agreement) {
      dispatch({ render: true, errors: { agreement: true } })
      return null
    }
    if (status === STATUS_UPLOAD_STORY['READY']) {
      const formData = new FormData()
      console.log('postAttribute :>> ', postAttribute)
      if (postAttribute.media && typeof postAttribute.media === 'object') {
        formData.append('post[media]', postAttribute.media)
        formData.append('post[media_type]', postAttribute.media_type)
      }
      if (!!defaultMedia && defaultMedia.url && postAttribute.media === null) {
        formData.append('post[_removed_media]', true)
      }

      formData.append(
        'post[content]',
        isBlankString(postAttribute.content) ? '' : postAttribute.content
      )
      formData.append('post[locale]', language === 'vi' ? 'vn' : language)

      dispatch({
        status: STATUS_UPLOAD_STORY['UPLOADING'],
        render: true
      })
      try {
        let resp
        if (uuid) {
          resp = await axios.patch(`/posts/${uuid}`, formData)
        } else {
          resp = await axios.post('/posts', formData)
        }
        const { success } = resp
        if (success) {
          history.push(`/${language}/acknowledgement`)
        }
      } catch (error) {
        if (error && error.code === 401) {
          userAuth.clearAuth()
          window.location.href = `/${language}`
        } else {
          const { errors } = error
          dispatch({
            status: STATUS_UPLOAD_STORY['READY'],
            errors: formatErrors(errors),
            render: true
          })
        }
      }
    } else {
      alert('Need to Agree firstly!!!!')
    }
  }
  function handleClickBack() {
    history.push(`/${language}`)
  }

  let canSubmit = false

  if (!isBlankString(content)) {
    canSubmit = true
  }
  if (
    isBlankString(content) &&
    defaultMedia &&
    !!defaultMedia.url &&
    media !== null
  ) {
    canSubmit = true
  }

  if (media !== null && typeof media === 'object') {
    canSubmit = true
  }

  return (
    <div className="px-4 lg:px-0 max-w-101 w-full mx-auto">
      <div className="flex flex-col items-center w-full sm:py-16 pb-16 pt-10 relative ">
        <div className="title relative w-full flex sm:items-center sm:justify-center sm:flex-row flex-col items-start ">
          <BackButton
            className="block sm:absolute sm:left-0 -top-3 sm:mb-0 mb-5"
            onClick={() => handleClickBack()}
          />
          <div className="text-primary text-3xl sm:text-10 font-bold text-center uppercase leading-none w-full">
            {uuid
              ? t('add_your_story.edit_your_story')
              : t('add_your_story.add_your_story')}
          </div>
        </div>
        <div
          className="main-form w-full form-container mt-10 px-4 sm:px-14 pt-3 pb-10 shadow-content-wrapper rounded-3xl"
          id="main_form"
        >
          <div className="form-wrapper" ref={observe} id="form_wrapper">
            <div className="upload-buttons-container">
              <MediaUploader
                assetId={postAttribute.id}
                defaultMedia={defaultMedia}
                onMediaSelect={(file) => handleMediaChange(file)}
              />
            </div>
            <div className="text-area-container mt-8 mb-7">
              <TextArea
                className={classNames({
                  'w-full border-2 focus:ring-0  rounded-3xl h-80 focus:outline-none outline-none py-7 px-11 text-gray placeholder-gray-opacity-50 resize-none text-lg': true
                })}
                defaultValue={content}
                placeholder={t('add_your_story.placeholder_caption')}
                onTextChange={(text) => handleChangeText(text)}
                error={errors['content']}
              />
            </div>
            <div className="agreement-container mb-5">
              <Agreement
                error={!!errors && errors.agreement}
                errorText={t('add_your_story.agree_before_posting')}
                onToggleAgreement={(status) => handleToggleAgreement(status)}
              />
            </div>
            <div className="submit-button-container flex justify-center">
              <button
                type="button"
                className={classNames({
                  'text-xl rounded-full text-white  border font-extrabold focus:ring-0 outline-none focus:outline-none py-1.5 px-1.5 transition-all duration-500 ease-in-out min-h-post-button min-w-post-button': true,
                  'bg-primary border-primary': canSubmit,
                  'bg-button-bg border-button-bg pointer-events-none':
                    !canSubmit || status === STATUS_UPLOAD_STORY['UPLOADING']
                })}
                onClick={submitResult}
              >
                {status === STATUS_UPLOAD_STORY['UPLOADING'] ? (
                  <div className="mt-1 text-center">
                    {t('add_your_story.post')}...
                  </div>
                ) : (
                  <div className="mt-1 text-center">
                    {t('add_your_story.post')}
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
